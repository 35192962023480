import React from "react";
import { BsFillCaretRightFill } from "react-icons/bs";
import { withRouter, Link } from "react-router-dom";

import "./style.scss";

const Breadcrump = (props) => {
  const { breadcrumpTitle } = props;

  return (
    <div className="breadcrump">
      <Link onClick={() => props.history.goBack()} className="link">
        Fondsen overview
      </Link>
      <BsFillCaretRightFill className="triangle" />
      <span className="breadcrump-title">{breadcrumpTitle}</span>
    </div>
  );
};

export default withRouter(Breadcrump);
