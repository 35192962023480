import React from 'react';
import { Navbar, Button, Container } from 'react-bootstrap';
import { BsFillPersonFill } from "react-icons/bs";

import logo from '../../assets/logo.png';

import './style.scss';

const Footer = ()=> {
    return (
        <footer className="footer">
            <Container>
                <p>&copy; Copyright Van Dooren Advies</p>
            </Container>
        </footer>
    )
}

export default Footer;