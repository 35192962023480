import React from "react";
import { Modal, Button } from "react-bootstrap";

import "./style.scss";

function ShowModal(props) {
  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> {props.children} </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Sluit</Button>
      </Modal.Footer>
    </Modal>
  );
}

class FilterModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  }

  generateListItems() {
    const output = this.props.data
      .sort() // place everything in alphabetical order
      .filter((a) => a !== null)
      .map((item) => item.toString())
      .map((item, i) => {
        const selected = this.props.selected.includes(item.replace("&", "%26"));
        return (
          <div key={i} className={selected ? " selected" : ""}>
            <label type="link" className="checkbox-container">
              <input
                type="checkbox"
                onChange={() =>
                  selected
                    ? this.props.onClearFilter(item.replace("&", "%26"))
                    : this.props.onAddFilter(item.replace("&", "%26"))
                }
                checked={selected}
              />{" "}
              <span className="checkmark"></span>
              <span className="checkbox-label">
                {item.replace("%26", "&").replace("_", " ")}{" "}
              </span>
            </label>
          </div>
        );
      });

    return output;
  }

  setModalShow(val) {
    this.setState({
      modalShow: val,
    });
  }

  render() {
    return (
      <>
        <div className="modal-button" onClick={() => this.setModalShow(true)}>
          Alle {this.props.title} &#9662;
        </div>
        <ShowModal
          show={this.state.modalShow}
          title={this.props.title}
          onHide={() => this.setModalShow(false)}
        >
          {this.generateListItems()}
        </ShowModal>
      </>
    );
  }
}

export default FilterModal;
