/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { AuthTypes } from "./actions";

export const login = (state, { data }) => ({
  ...state,
  TOKEN: data.jwt,
  isAuth: data.user ? data.user.id : false,
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN]: login,
});

export default reducer;
