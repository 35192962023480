import React from "react";
import { Button } from "react-bootstrap";
import qs from "qs";
import { BsSearch } from "react-icons/bs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import fundsActions from "../../redux/funds/actions";

import "./style.scss";
import { api } from "../../services/ApiService";

class Search extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      toggledOn: false,
      isLoading: true,
      activeFilters: {},
    };
  }

  handleSearch() {
    const value = this.state.query;

    let activeFilters = qs.parse(this.props.location.search);
    let query = qs.stringify({ _q: value, _limit: 5 }, { encode: false });

    if (activeFilters["_q"]) {
      delete activeFilters["_q"];
    }

    this.props.updateActiveSearch(value);
    this.props.history.push({
      pathname: "/",
      search: query,
      state: {
        activeFilters,
      },
    });

    if (value) {
      api
        .fetchFunds(query)
        .then((res) => {
          this.props.updateFunds(res);
          res.length === 0 && this.handleHistorySearch(query);
        })
        .catch((err) => console.log(err));
      api
        .getItemCount(query)
        .then((res) => this.props.updateItemCount(res))
        .catch((err) => console.log(err));
    } else if (!value) {
      window.location.href = "/";
    }
  }

  handleHistorySearch(query) {
    api
      .searchHistory(query)
      .then((res) => {
        const funds = res.map((el) => {
          return el.fonds;
        });
        this.props.updateFunds(funds);
      })
      .catch((err) => console.log(err));
    api
      .getHistoryItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => console.log(err));
  }

  onKeyUp(e) {
    if (e.charCode === 13) {
      this.handleSearch();
    }
  }

  render() {
    return (
      <div className="filter-item open">
        <div className="title">{this.props.title} </div>

        <div className={"search-wrapper"}>
          <label type="link">
            <div className="input-wrapper">
              <input
                className="input-field"
                type="text"
                placeholder={this.props.title}
                onChange={(e) => this.setState({ query: e.target.value })}
                onKeyPress={(e) => this.onKeyUp(e)}
              />{" "}
              <BsSearch
                onClick={() => this.handleSearch()}
                className="submit-button"
              />
            </div>
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ funds }) => ({ activeFilters: funds.activeFilters });
const mapDispatchToProps = (dispatch) => ({
  updateActiveSearch: (activeSearch) =>
    dispatch(fundsActions.updateActiveSearch(activeSearch)),
  updateFunds: (funds) => dispatch(fundsActions.updateFunds(funds)),
  updateItemCount: (itemCount) =>
    dispatch(fundsActions.updateItemCount(itemCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
