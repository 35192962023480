import React from "react";
import DetailsPageLayout from "../../layouts/DetailsPageLayout";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import fundDetailsActions from "../../redux/funds/actions";
import { api } from "../../services/ApiService";
import ReactMarkdown from "react-markdown";

import "./style.scss";

class DetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const query = this.props.match.params.id;

    api
      .fetchFundDetails(query)
      .then((res) => {
        this.props.updateFundDetails(res);
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.fundDetails !== this.props.fundDetails) {
      this.setState({
        data: this.props.fundDetails[0],
      });
    }
  }

  LinkRenderer(props) {
    return (
      <a href={props.href} target="_blank">
        {props.children}
      </a>
    );
  }

  render() {
    const { data } = this.state;

    if (!data) return null;

    console.log(data);

    return (
      <DetailsPageLayout
        title={data.naam}
        breadcrumpTitle={data.naam}
        data={data}
      >
        <span className="sub-title">Donatiebeleid</span>
        <ReactMarkdown className="main-text">
          {data.donatiebeleid}
        </ReactMarkdown>

        <span className="sub-title">beoordelingscriteria</span>
        <ReactMarkdown className="main-text">
          {data.beoordelingscriteria}
        </ReactMarkdown>

        <span className="sub-title">Deadlines en behandeltermijn</span>
        <ReactMarkdown className="main-text">
          {data.deadlines_en_behandeltermijn}
        </ReactMarkdown>

        <span className="sub-title">Overige informatie</span>
        <ReactMarkdown className="main-text">
          {data.overige_informatie}
        </ReactMarkdown>

        <span className="sub-title">meesturen</span>
        <ReactMarkdown className="main-text">{data.meesturen}</ReactMarkdown>

        <span className="sub-title ">Relevante links</span>
        <ReactMarkdown
          className="main-text relevante-links"
          source={data.relevante_links}
          renderers={{ link: this.LinkRenderer }}
        >
          {data.relevante_links}
        </ReactMarkdown>

        <span className="sub-title">contactinformatie</span>
        {data.contactinformatie && (
          <table>
            <tbody>
              <tr>
                <th>Naam:</th>
                <td>{data.contactinformatie.naam}</td>
              </tr>
              <tr>
                <th>Adres:</th>
                <td>{data.contactinformatie.Adres}</td>
              </tr>
              <tr>
                <th>Postbus:</th>
                <td>{data.contactinformatie.Postbus}</td>
              </tr>
              <tr>
                <th>Postcode:</th>
                <td>{data.contactinformatie.Postcode}</td>
              </tr>
              <tr>
                <th>Plaats:</th>
                <td>{data.contactinformatie.Plaats}</td>
              </tr>
              <tr>
                <th>Land:</th>
                <td>{data.contactinformatie.Land}</td>
              </tr>

              {data.contactinformatie.Contactpersoon.map((item, i) => (
                <tr className="contact-row" key={item.id}>
                  <th>Contactpersoon {i + 1}:</th>
                  <td>
                    {item.naam} <br /> {item.Telefoonnummer} <br />
                    {item.Email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DetailsPageLayout>
    );
  }
}

const mapStateToProps = ({ funds }) => ({ fundDetails: funds.fundDetails });
const mapDispatchToProps = (dispatch) => ({
  updateFundDetails: (fundDetails) =>
    dispatch(fundDetailsActions.updateFundDetails(fundDetails)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailsPage));
