import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sider from "../../components/Sider";
import Breadcrump from "../../components/Breadcrump";
import DetailsSider from "../../components/DetailsSider";
import HistoryTable from "../../components/HistoryTable";
import { withRouter } from "react-router-dom";

import "./style.scss";

const DetailsPageLayout = (props) => {
  const { title, children, breadcrumpTitle, data } = props;

  return (
    <>
      <Container className="page">
        <Header />
        <Breadcrump breadcrumpTitle={breadcrumpTitle}></Breadcrump>
        <h1 className="detailpage-title">{title}</h1>
        <Row>
          <Col>{children}</Col>
          <Sider>
            <DetailsSider data={data} />
          </Sider>
        </Row>
        <Container>
          <Row>
            <HistoryTable data={data} />
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default withRouter(DetailsPageLayout);
