import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import DetailsPage from "./pages/DetailsPage";
import qs from "qs";
import PrivateRoute from "./components/PrivateRoute";

import fundsActions from "./redux/funds/actions";
import Overview from "./pages/Overview";
import { api } from "./services/ApiService";
import Login from "./pages/Login";

const App = ({ updateActiveFilters, updateFilters, updateFunds }) => {
  useEffect(() => {
    const activeFilters = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const query = qs.stringify(activeFilters, { encode: false });
    updateActiveFilters(activeFilters);

    api
      .fetchMatchingFilters(query)
      .then((filters) => updateFilters(filters))
      .catch((err) => console.dir({ err }));
    api
      .fetchFunds(query)
      .then((res) => updateFunds(res))
      .catch((err) => console.log(err));
  }, [updateActiveFilters]);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/login" component={() => <Login />} />
        <PrivateRoute exact path="/:id" component={() => <DetailsPage />} />
        <PrivateRoute exact path="/" component={() => <Overview />} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveFilters: (activeFilters) =>
    dispatch(fundsActions.updateActiveFilters(activeFilters)),
  updateFunds: (funds) => dispatch(fundsActions.updateFunds(funds)),
  updateFilters: (filters) => dispatch(fundsActions.updateFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
