import axios from "axios";
import { config } from "../config";

const itemLimit = 5;

function userToken() {
  const token = localStorage.getItem("jwtToken");
  return token;
}

const apiClient = axios.create({
  baseURL: config.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  timeout: 3000,
});

apiClient.interceptors.request.use(
  async function (options) {
    const token = await userToken();

    options.headers.Authorization = token ? `Bearer ${token}` : "";
    return options;
  },
  function (error) {
    console.log("Request error: ", error);
    return Promise.reject(error);
  }
);

function fetchFunds(query) {
  let url = `/fonds?_sort=updated_at:DESC&_start=0&_limit=${itemLimit}`;

  if (query) url = "/fonds?_sort=updated_at:DESC&" + query;

  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      if (e.response.status === 403 || e.response.status === 401) {
        if (
          window.localStorage.jwtToken &&
          window.location.pathname !== "/login"
        ) {
          window.localStorage.clear();
          window.location.replace("/login");
        }
      }
    });
}

function searchHistory(query) {
  let url;

  if (query) url = "/histories?" + query;

  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

function getItemCount(query) {
  let url = "/fonds/count";

  if (query) url = "/fonds/count?" + query;

  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      if (e.response.status === 403 || e.response.status === 401) {
        if (
          window.localStorage.jwtToken &&
          window.location.pathname !== "/login"
        ) {
          window.localStorage.clear();
          window.location.replace("/login");
        }
      }
    });
}

function getHistoryItemCount(query) {
  let url = "/histories/count";

  if (query) url = "/histories/count?" + query;

  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

function fetchFundDetails(query) {
  let url = "/fonds?id=" + query;

  return apiClient
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    });
}

function fetchFilters(query) {
  let url;

  if (query) {
    url = `/fonds/filters?${query}`;
  } else {
    url = "/fonds/filters";
  }

  return apiClient
    .get(url)
    .then((response) => response.data)
    .catch((e) => e);
}

function fetchMatchingFilters(query) {
  let url;
  if (query) {
    url = `/fonds/filters?${query}`;
  } else {
    url = "/fonds/filters";
  }

  return apiClient
    .get(url)
    .then((response) => response.data)
    .catch((e) => e);
}

function login(data) {
  let url = "/auth/local";

  return apiClient.post(url, data).then((res) => res.data);
}

export const api = {
  fetchFunds,
  fetchFundDetails,
  fetchFilters,
  fetchMatchingFilters,
  login,
  getItemCount,
  searchHistory,
  getHistoryItemCount,
};
