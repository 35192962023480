import React from "react";
import { Navbar, Button } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { withRouter } from "react-router-dom";

import logo from "../../assets/logo.png";

import "./style.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  logOut = () => {
    localStorage.getItem("jwtToken") && localStorage.removeItem("jwtToken");
    this.props.history.push("/login");
  };

  render() {
    return (
      <Navbar sticky="top" className="header justify-content-between">
        <Navbar.Brand>
          <img
            className="logo"
            src={logo}
            alt="logo"
            onClick={() => (window.location.href = "/")}
          />
        </Navbar.Brand>
        <Button className="logout-btn" onClick={this.logOut}>
          Uitloggen <BsFillPersonFill />
        </Button>
      </Navbar>
    );
  }
}

export default withRouter(Header);
