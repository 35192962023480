import React from "react";

import "./style.scss";

const FilterInfoHeader = ({ search, itemCount }) => {
  let query = search ? search.toUpperCase() : false;

  return (
    <div className="filter-results">
      <p className="text">
        {query && `GEZOCHT OP "${query}"`} {itemCount}{" "}
        {itemCount > 1 || itemCount === 0 ? "RESULTATEN" : "RESULTAAT"} GEVONDEN
      </p>
    </div>
  );
};

export default FilterInfoHeader;
