import React from "react";
import { Container, Row, Form, Button, Spinner } from "react-bootstrap";
import serialize from "form-serialize";
import { withRouter, Redirect } from "react-router-dom";
import { BsFillEyeFill, BsEnvelope } from "react-icons/bs";
import { RiKeyFill } from "react-icons/ri";

import { api } from "../../services/ApiService";
import logo from "../../assets/logo.png";
import "./style.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoading: false,
      redirect: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("jwtToken");
    if (user && user !== "undefined") {
      this.props.history.push("/");
    }
  }

  errorMessage = () => {
    if (this.state.error)
      return (
        <span className="error-message">Ongeldig emailadres of wachtwoord</span>
      );
  };

  togglePasswordVisiblity = () => {
    this.state.showPassword
      ? this.setState({ showPassword: false })
      : this.setState({ showPassword: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = serialize(e.target, { hash: true });

    this.setState({
      isLoading: true,
    });

    api
      .login(formData)
      .then((res) => {
        if (res) {
          localStorage.setItem("jwtToken", res.jwt);
          this.setState({
            isLoading: false,
            redirect: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: true,
          isLoading: false,
        });
        console.log(err);
      });
  };

  render() {
    const { isLoading, redirect } = this.state;

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status" className="loading-spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }
    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <div className="login">
        <Container className="h-100">
          <Row className="h-100 justify-content-center align-items-center">
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <div className="logo-wrapper">
                <img className="logo" src={logo} alt="logo" />
              </div>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <div className="input-wrapper">
                  <BsEnvelope className="input-icon" />
                  <Form.Control
                    name="identifier"
                    type="email"
                    placeholder="Je emailadres"
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Wachtwoord</Form.Label>
                <div className="input-wrapper">
                  <RiKeyFill className="input-icon" />
                  <Form.Control
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Je wachtwoord"
                  />
                  <BsFillEyeFill
                    className="pass-eye"
                    onClick={this.togglePasswordVisiblity}
                  />
                </div>
              </Form.Group>

              {this.errorMessage()}

              <Button variant="primary" type="submit">
                Log in
              </Button>
            </Form>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Login);
