import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import Filters from '../../components/Filters';
import Footer from '../../components/Footer';
import Sider from '../../components/Sider';

import './style.scss';
import { withRouter } from 'react-router-dom';

const PageLayout = ({title, children}) => {
    return (
        <>
            <Container className="page">
                <Header/>
                <h1 className="page-title">{title}</h1>
                <Row className="page-content">
                    <Sider>
                        <Filters/>
                    </Sider>
                    <Col>{children}</Col>
                </Row>
            </Container>
            <Footer/>
        </>
    );
}

export default withRouter(PageLayout)