import React from "react";
import { Table } from "react-bootstrap";
import { BsFileEarmarkText } from "react-icons/bs";
import { config } from "../../config";
import PieDiagram from "../PieChart";

import "./style.scss";

// shorten name of file to fit in element and still show filetype
const truncateFilename = (fileName) => {
  let name = fileName;
  let ext = name
    .substring(name.lastIndexOf(".") + 1, name.length)
    .toLowerCase();
  let filename = name.substring(0, name.lastIndexOf("."));

  if (filename.length <= 20) {
    return name;
  }
  filename = filename.substr(0, 20) + (name.length > 20 ? "[...]" : "");
  return filename + "." + ext;
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";

    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  return { items: sortedItems, requestSort, sortConfig };
};

const HistTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.data);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <React.Fragment>
      <span className="sub-title">Historie</span>

      {props.data.length > 0 && <PieDiagram data={items} />}

      <Table responsive>
        <thead>
          <tr>
            <th className="sortable">
              <div
                onClick={() => requestSort("datum")}
                className={getClassNamesFor("datum")}
              >
                Datum
              </div>
            </th>
            <th>opdrachtgever</th>
            <th>project</th>
            <th>Aangevraagd bedrag</th>
            <th className="sortable">
              <div
                onClick={() => requestSort("toegekend_bedrag")}
                className={getClassNamesFor("toegekend_bedrag")}
              >
                Toegekend bedrag
              </div>
            </th>
            <th className="sortable">
              <div
                onClick={() => requestSort("beoordeling")}
                className={getClassNamesFor("beoordeling")}
              >
                Beoordeling
              </div>
            </th>
            <th>aanvrager</th>
            <th>Toekenning / Afwijzingsbrief</th>
          </tr>
        </thead>
        <tbody>
          {items
            .filter((a) => a !== null)
            .map((item) => (
              <tr key={item.id}>
                <td>
                  {item.datum && item.datum.split("-").reverse().join("-")}
                </td>
                <td>{item.opdrachtgever}</td>
                <td>{item.project}</td>
                <td>
                  {item.aangevraagd_bedrag &&
                    item.aangevraagd_bedrag.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ) + ",-"}
                </td>
                <td>
                  {item.toegekend_bedrag &&
                    item.toegekend_bedrag.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ) + ",-"}
                </td>
                <td>{item.beoordeling}</td>
                <td>{item.aanvrager}</td>
                <td>
                  {item.toekenning_afwijzingsbrief && (
                    <div className="documents">
                      <BsFileEarmarkText className="document-icon" />
                      <a
                        href={
                          config.API_URL + item.toekenning_afwijzingsbrief.url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateFilename(item.toekenning_afwijzingsbrief.name)}
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default function HistoryTable(props) {
  return <HistTable data={props.data.historie} className="history-table" />;
}
