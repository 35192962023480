/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { AuthTypes } from "./actions";

export const updateFunds = (state, { funds }) => ({
  ...state,
  funds,
});

export const updateActiveFilters = (state, { activeFilters }) => ({
  ...state,
  activeFilters,
});

export const updateFilters = (state, { filters }) => ({
  ...state,
  ...filters,
});

export const updateFundDetails = (state, { fundDetails }) => ({
  ...state,
  fundDetails,
});

export const updateActiveSearch = (state, { activeSearch }) => ({
  ...state,
  activeSearch,
});

export const updateItemCount = (state, { itemCount }) => ({
  ...state,
  itemCount,
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.UPDATE_FUNDS]: updateFunds,
  [AuthTypes.UPDATE_ACTIVE_FILTERS]: updateActiveFilters,
  [AuthTypes.UPDATE_FILTERS]: updateFilters,
  [AuthTypes.UPDATE_FUND_DETAILS]: updateFundDetails,
  [AuthTypes.UPDATE_ACTIVE_SEARCH]: updateActiveSearch,
  [AuthTypes.UPDATE_ITEM_COUNT]: updateItemCount,
});

export default reducer;
