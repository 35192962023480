import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./auth/reducers";
import fundsReducer from "./funds/reducers";
import fundDetailsReducer from "./funds/reducers";

const expireReducer = require("redux-persist-expire");

let whitelist = ["auth"];

// if(process.env.NODE_ENV === 'development') {
// 	whitelist.push('auth')
// }

const persistConfig = {
  key: "root",
  storage,
  whitelist,
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer("auth", {
      // (Required) Seconds after which store will be expired
      expireSeconds: 1800,
      autoExpire: true,
    }),
  ],
};

const reducers = combineReducers({
  auth: authReducer,
  funds: fundsReducer,
  fundDetails: fundDetailsReducer,
});

export default persistReducer(persistConfig, reducers);
