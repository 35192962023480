/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  funds: [],
  activeFilters: {},
  fundDetails: [],
  activeSearch: "",
  type_fonds: ["Overheidssubsidie", "Bedrijfsfonds", "Loterij"],
  interessegebieden: [
    "(Klassieke) muziek",
    "Architectuur",
    "Armoede/Zelfredzaamheid",
    "Beeldende kunst",
    "Cultuur",
    "Design",
  ],
  werkgebieden: ["Nederland", "Internationaal"],
  regios: [
    "Drenthe",
    "Flevoland",
    "Friesland",
    "Gelderland",
    "Provincie Groningen",
  ],
  steden: ["Amsterdam", "Rotterdam", "Utrecht", "Den Haag"],
  aanvraagbudgetten: [
    "€0 - €5.000",
    "€5.000 - €25.000",
    "€25.000 +",
    "Onbekend",
  ],
  beoordeling: ["Toegekend", "Afgewezen"],
};
