import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Col } from "react-bootstrap";

import "./style.scss";

const Sider = ({ children }) => {
  return (
    <Col md={4}>
      <aside className="sider">{children}</aside>

      {useParams().id && (
        <span className="date-stamp">
          Laatst gewijzigd op{" "}
          {moment(children.props.data.updated_at).format("DD-MM-YYYY")}
        </span>
      )}
    </Col>
  );
};

export default Sider;
