import React from "react";
import { Card } from "react-bootstrap";
import qs from "qs";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import { Pagination } from "antd";

import "./style.scss";
import fundActions from "../../redux/funds/actions";
import PageLayout from "../../layouts/PageLayout";
import FilterInfoHeader from "../../components/FilterInfoHeader";
import { api } from "../../services/ApiService";
import { withRouter } from "react-router-dom";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      itemLimit: 5,
    };
  }

  componentDidMount() {
    const activeFilters = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    let query;

    if (Object.entries(activeFilters).length > 0) {
      query = qs.stringify(activeFilters, { encode: false });

      this.handleActiveFilters(activeFilters);

      api
        .fetchMatchingFilters(query)
        .then((filters) => this.props.updateFilters(filters))
        .catch((err) => {
          this.setState({ error: true });
          console.dir({ err });
        });
      api
        .fetchFunds(query)
        .then((res) => {
          this.props.updateFunds(res);
          res.length === 0 && this.handleHistorySearch(query);
        })
        .catch((err) => {
          this.setState({ error: true });
          console.log(err);
        });
    }
    api
      .getItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => {
        this.setState({ error: true });
        console.log(err);
      });
  }

  handleHistorySearch(query) {
    api
      .searchHistory(query)
      .then((res) => {
        const funds = res.map((el) => {
          return el.fonds;
        });
        this.props.updateFunds(funds);
      })
      .catch((err) => console.log(err));
    api
      .getHistoryItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => console.log(err));
  }

  buttonRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  handlePageChange = (page) => {
    const limit = this.state.itemLimit;
    const start = limit * page - limit;
    const activeFilters = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    let query;

    if (Object.entries(activeFilters).length > 0) {
      query = qs.stringify(
        {
          _start: start,
          _where: activeFilters._where,
          _q: activeFilters._q,
          _limit: limit,
        },
        { encode: false }
      );
    } else query = qs.stringify({ _start: start, _limit: limit });

    api
      .fetchMatchingFilters()
      .then((filters) => this.props.updateFilters(filters))
      .catch((err) => {
        this.setState({ error: true });
        console.log(err);
      });
    api
      .fetchFunds(query)
      .then(
        (res) => this.props.updateFunds(res),
        this.setState({
          isLoading: false,
          currentPage: page,
        })
      )
      .catch((err) => {
        this.setState({ error: true });
        console.log(err);
      });

    this.props.history.push({
      pathname: "/",
      search: query,
    });
  };

  handleActiveFilters(activeFilters) {
    if (Object.entries(activeFilters).length > 0) {
      let obj = Object.entries(activeFilters)[0][1];
      let filters = {};

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const val = obj[key];

          if (key === "_or") {
            for (const [key, value] of Object.entries(val[0][0])) {
              if (value > 0 && value <= 5000) {
                filters[key.split("_")[0]] = "€0 - €5.000";
              } else if (value > 5000 && value <= 25000) {
                filters[key.split("_")[0]] = "€5.000 - €25.000";
              } else if (value > 25000) {
                filters[key.split("_")[0]] = "€25.000 +";
              } else filters[key.split("_")[0]] = "Onbekend";
            }
          }

          if (key === "historie.beoordeling") {
            filters[key.split(".")[1]] = val;
          } else filters[key.split(".")[0]] = val;
        }
      }
      this.props.updateActiveFilters(filters);
    }
  }

  render() {
    if (this.state.error) {
      return <span>Server error</span>;
    }

    return (
      <PageLayout title="Overzicht van de fondsen & subsidies">
        <FilterInfoHeader
          search={this.props.funds.activeSearch}
          itemCount={this.props.itemCount}
        />

        {this.props.funds.funds.length === 0 && (
          <Card className="mb-3">
            <Card.Body>
              <h5 style={{ margin: "0" }}>
                Verbreed je zoekterm om meer resultaten te krijgen
              </h5>
            </Card.Body>
          </Card>
        )}

        {this.props.funds.funds
          .filter((a) => a !== null)
          .map((item, i) => {
            return (
              <Card key={i} className="mb-3">
                <Card.Body>
                  <h5 className="fund-title">{item.naam}</h5>
                  <p className="fund-text">{item.donatiebeleid}</p>
                  <Link to={"/" + item.id}>
                    Lees verder <BsFillCaretRightFill />
                  </Link>
                </Card.Body>
              </Card>
            );
          })}

        {this.props.itemCount > 5 && (
          <Pagination
            defaultPageSize={this.state.itemLimit}
            current={this.state.currentPage}
            total={this.props.itemCount}
            onChange={(page) => {
              this.handlePageChange(page);
            }}
            showSizeChanger={false}
            itemRender={this.buttonRender}
          />
        )}
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ funds }) => ({
  funds,
  activeFilters: funds.activeFilters,
  activeSearch: funds.activeSearch,
  itemCount: funds.itemCount,
});
const mapDispatchToProps = (dispatch) => ({
  updateFunds: (funds) => dispatch(fundActions.updateFunds(funds)),
  updateItemCount: (itemCount) =>
    dispatch(fundActions.updateItemCount(itemCount)),
  updateActiveFilters: (filters) =>
    dispatch(fundActions.updateActiveFilters(filters)),
  updateFilters: (filters) => dispatch(fundActions.updateFilters(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview));
