import React from "react";
import { Chart } from "react-google-charts";

class PieDiagram extends React.Component {
  constructor(props) {
    super(props);
    {
    }
  }

  getCount = (val) => {
    const arr = this.props.data.map((item) => item.beoordeling);

    const countOccurrences = (arr, val) =>
      arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    return countOccurrences(arr, val);
  };

  render() {
    return (
      <div className="pie-chart">
        <Chart
          style={{ width: "100%", height: "300px" }}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["beoordeling", "Aantal"],
            ["Toegekend", this.getCount("Toegekend")],
            ["Afgewezen", this.getCount("Afgewezen")],
          ]}
          options={{
            title: "beoordeling",
            colors: ["#be9f56", "#55606e"],
          }}
        />
      </div>
    );
  }
}

export default PieDiagram;
