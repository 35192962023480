import React from "react";
import qs from "qs";
import assert from "assert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FilterItem from "../FilterItem";
import fundActions from "../../redux/funds/actions";
import SearchItem from "../SearchItem";
import { Button } from "react-bootstrap";
import { api } from "../../services/ApiService";

const initState = {
  type_fonds: [],
  hasFilters: false,
  activeFilters: {},
};

class Filters extends React.Component {
  constructor(props) {
    super(props);

    const params = qs.parse(props.location.search);

    this.state = {
      ...initState,
      ...params,
    };
  }

  clearFilter = () => {
    let query = qs.stringify({ _limit: 5, _start: 0 }, { encode: false });

    this.props.history.push({
      pathname: "/",
      search: query,
      state: {
        hasFilters: false,
      },
    });

    this.props.updateActiveFilters({});

    api
      .fetchMatchingFilters()
      .then((filters) => this.props.updateFilters(filters))
      .catch((err) => console.dir({ err }));
    api
      .fetchFunds(query)
      .then((res) => this.props.updateFunds(res))
      .catch((err) => console.log(err));
    api
      .getItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="filters">
        <nav>
          <SearchItem title="Zoekterm" target="_q" />
          <br />

          {this.props.interessegebieden.length > 0 && (
            <>
              <FilterItem
                title="Interessegebieden"
                data={this.props.interessegebieden}
                target="interessegebieden"
                type="multiple"
                toggled={
                  this.props.activeFilters.interessegebieden &&
                  this.props.activeFilters.interessegebieden.length > 0
                }
                defaultSelected={this.props.activeFilters.interessegebieden}
              />
              <br />
            </>
          )}

          {this.props.type_fonds.length > 0 && (
            <>
              <FilterItem
                title="Type fonds"
                data={this.props.type_fonds}
                target="type_fonds"
                type="multiple"
                toggled={
                  this.props.activeFilters.type_fonds &&
                  this.props.activeFilters.type_fonds.length > 0
                }
                defaultSelected={this.props.activeFilters.type_fonds}
              />
              <br />
            </>
          )}

          {this.props.werkgebieden.length > 0 && (
            <>
              <FilterItem
                title="Werkgebied"
                data={this.props.werkgebieden}
                target="werkgebieden"
                type="multiple"
                toggled={
                  this.props.activeFilters.werkgebieden &&
                  this.props.activeFilters.werkgebieden.length > 0
                }
                defaultSelected={this.props.activeFilters.werkgebieden}
              />
              <br />
            </>
          )}

          {this.props.regios.length > 0 && (
            <>
              <FilterItem
                title="Regio"
                data={this.props.regios}
                target="regios"
                type="multiple"
                toggled={
                  this.props.activeFilters.regios &&
                  this.props.activeFilters.regios.length > 0
                }
                defaultSelected={this.props.activeFilters.regios}
              />
              <br />
            </>
          )}

          {this.props.steden.length > 0 && (
            <>
              <FilterItem
                title="Verdere Specificatie"
                data={this.props.steden}
                target="steden"
                type="multiple"
                toggled={
                  this.props.activeFilters.steden &&
                  this.props.activeFilters.steden.length > 0
                }
                defaultSelected={this.props.activeFilters.steden}
              />
              <br />
            </>
          )}

          {this.props.aanvraagbudgetten.length > 0 && (
            <>
              <FilterItem
                title="aanvraagbudgetten"
                data={this.props.aanvraagbudgetten}
                target="aanvraagbudgetten"
                type="multiple"
                toggled={
                  this.props.activeFilters.aanvraagbudgetten &&
                  this.props.activeFilters.aanvraagbudgetten.length > 0
                }
                defaultSelected={this.props.activeFilters.aanvraagbudgetten}
              />
              <br />
            </>
          )}

          {this.props.beoordeling && (
            <>
              <FilterItem
                title="beoordeling"
                data={this.props.beoordeling}
                target="beoordeling"
                type="multiple"
                toggled={
                  this.props.activeFilters.beoordeling &&
                  this.props.activeFilters.beoordeling.length > 0
                }
                defaultSelected={this.props.activeFilters.historie}
              />
              <br />
            </>
          )}

          {Object.keys(this.props.activeFilters).length > 0 && (
            <Button className="filter-btn" onClick={() => this.clearFilter()}>
              Reset filters
            </Button>
          )}
        </nav>
      </div>
    );
  }
}

const mapStateToProps = ({ funds }) => ({
  activeFilters: funds.activeFilters,
  type_fonds: funds.type_fonds,
  interessegebieden: funds.interessegebieden,
  werkgebieden: funds.werkgebieden,
  regios: funds.regios,
  steden: funds.steden,
  aanvraagbudgetten: funds.aanvraagbudgetten,
  beoordeling: funds.beoordeling,
});

const mapDispatchToProps = (dispatch) => ({
  updateFunds: (funds) => dispatch(fundActions.updateFunds(funds)),
  updateActiveFilters: (filters) =>
    dispatch(fundActions.updateActiveFilters(filters)),
  updateFilters: (filters) => dispatch(fundActions.updateFilters(filters)),
  updateItemCount: (itemCount) =>
    dispatch(fundActions.updateItemCount(itemCount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Filters));
