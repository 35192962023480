import React from "react";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FilterModal from "../FilterModal";
import fundsActions from "../../redux/funds/actions";

import "./style.scss";
import { api } from "../../services/ApiService";
import { BsSearch } from "react-icons/bs";
import { it } from "date-fns/locale";

let itemLimit = 5;

class FilterItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      toggledOn: false,
      isLoading: true,
      activeFilters: {},
      data: props.data,
    };
  }

  getQuery(activeFilters) {
    let obj = {};

    for (const key in activeFilters) {
      if (activeFilters.hasOwnProperty(key)) {
        const value = activeFilters[key];

        switch (key) {
          case "type_fonds":
            obj[key] = value;
            break;
          case "aanvraagbudgetten":
            let arr = [];

            if (value.includes("€0 - €5.000")) {
              arr.push([{ [`${key}_lte`]: 5000 }]);
            }
            if (value.includes("€5.000 - €25.000")) {
              arr.push([{ [`${key}_gte`]: 5001 }, { [`${key}_lte`]: 25000 }]);
            }
            if (value.includes("€25.000 +")) {
              arr.push([{ [`${key}_gte`]: 25001 }]);
            }
            if (value.includes("Onbekend")) {
              arr.push([{ [`${key}`]: 0 }]);
            }
            obj = { _or: arr };
            break;
          case "beoordeling":
            obj[`historie.${key}`] = value;
            break;
          case "0":
            break;
          default:
            obj[`${key}.naam`] = value;
        }
      }
    }
    return obj;
  }

  clearFilter(item) {
    let { activeFilters, target } = this.props;

    activeFilters[target] = activeFilters[target].filter((el) => el !== item);

    const queryObj = this.getQuery(activeFilters);

    let query = qs.stringify(
      { _where: queryObj, _limit: itemLimit },
      { encode: false }
    );

    console.log({ msg: "clear", query });

    this.props.history.push({
      pathname: "/",
      search: query,
      state: {
        activeFilters,
      },
    });

    this.props.updateActiveFilters(activeFilters);

    api
      .fetchMatchingFilters()
      .then((filters) => this.props.updateFilters(filters))
      .catch((err) => console.dir({ err }));
    api
      .fetchFunds(query)
      .then((res) => this.props.updateFunds(res))
      .catch((err) => console.log(err));
    api
      .getItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => console.log(err));
  }

  addFilter(item) {
    let activeFilters = this.props.activeFilters;
    const target = this.props.target;

    if (!activeFilters[target]) {
      activeFilters[target] = [];
    }

    if (!activeFilters[target].includes(item)) {
      activeFilters[target].push(item);
    }

    const queryObj = this.getQuery(activeFilters);

    let query = qs.stringify(
      { _where: queryObj, _limit: itemLimit },
      { encode: false }
    );

    this.props.history.push({
      pathname: "/",
      search: query,
      state: {
        activeFilters,
      },
    });

    this.props.updateActiveFilters(activeFilters);

    api
      .fetchMatchingFilters(query)
      .then((filters) => this.props.updateFilters(filters))
      .catch((err) => console.dir({ err }));
    api
      .fetchFunds(query)
      .then((res) => this.props.updateFunds(res))
      .catch((err) => console.log(err));
    api
      .getItemCount(query)
      .then((res) => this.props.updateItemCount(res))
      .catch((err) => console.log(err));
  }

  generateListItems() {
    const { activeFilters, target } = this.props;

    const output = this.state.data
      .sort() // place everything in alphabetical order
      .filter((a) => a !== null)
      .map((item) => item.toString())
      .map((item, i) => {
        const selected =
          activeFilters[target] &&
          activeFilters[target].includes(item.replace("&", "%26"));

        return (
          <div
            key={i}
            className={(i < 5 ? "" : "hidden") + (selected ? " selected" : "")}
          >
            <label type="link" className="checkbox-container">
              <input
                type="checkbox"
                onChange={() =>
                  selected
                    ? this.clearFilter(item.replace("%26", "&"))
                    : this.addFilter(item.replace("%26", "&"))
                }
                checked={selected}
              />{" "}
              <span className="checkmark"></span>
              <span className="checkbox-label">
                {item
                  .replace("%26", "&")
                  .replace("_", " ")
                  .replace(/([a-z])([A-Z])/g, "$1/$2")}
              </span>
            </label>
          </div>
        );
      });

    return output;
  }

  filterData(value) {
    const { data } = this.props;

    if (value === "") {
      this.setState({
        data: this.props.data,
      });
    } else {
      let output = data.filter((item) => item.toLowerCase().includes(value));

      this.setState({
        data: output,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.data !== oldProps.data) {
      this.setState({
        data: this.props.data,
      });
    }

    if (this.props.toggled !== oldProps.toggled) {
      this.setState({
        toggledOn: this.props.toggled,
      });
    }

    if (this.props.defaultSelected !== oldProps.defaultSelected) {
      if (typeof this.props.defaultSelected === "object") {
        this.setState({
          selected: this.props.defaultSelected || [],
        });
      } else {
        this.setState({
          selected: [this.props.defaultSelected] || [],
        });
      }
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.defaultSelected !== props.defaultSelected) {
      if (typeof this.props.defaultSelected === "object") {
        this.setState({
          selected: this.props.defaultSelected || [],
        });
      } else {
        this.setState({
          selected: [this.props.defaultSelected] || [],
        });
      }
    }
  }

  render() {
    return (
      <div className={"filter-item" + (this.state.toggledOn ? " open" : "")}>
        <div
          className="title"
          onClick={() => this.setState({ toggledOn: !this.state.toggledOn })}
        >
          {this.props.title}{" "}
        </div>

        {this.props.data.length > 5 && (
          <div className="search-wrapper">
            <label type="link">
              <div className="input-wrapper">
                <BsSearch className="search-icon" />
                <input
                  className="filter-search"
                  type="text"
                  onChange={(e) => this.filterData(e.target.value)}
                />{" "}
              </div>
            </label>
          </div>
        )}

        {this.generateListItems()}

        {this.props.data.length > 5 && (
          <FilterModal
            title={this.props.target}
            generateListItems={this.generateListItems}
            data={this.props.data}
            selected={this.state.selected}
            onAddFilter={(val) => this.addFilter(val)}
            onClearFilter={(val) => this.clearFilter(val)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ funds }) => ({ activeFilters: funds.activeFilters });
const mapDispatchToProps = (dispatch) => ({
  updateActiveFilters: (activeFilters) =>
    dispatch(fundsActions.updateActiveFilters(activeFilters)),
  updateFunds: (funds) => dispatch(fundsActions.updateFunds(funds)),
  updateFilters: (filters) => dispatch(fundsActions.updateFilters(filters)),
  updateItemCount: (itemCount) =>
    dispatch(fundsActions.updateItemCount(itemCount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterItem));
