import React from "react";
import { BsFileEarmarkText } from "react-icons/bs";
import { config } from "../../config";
import ReactMarkdown from "react-markdown";

import "./style.scss";

const DetailsSider = (props) => {
  const { data } = props;

  function getBudget() {
    let budget = data.aanvraagbudgetten;
    if (budget > 0 && budget <= 5000) return "€0 - €5.000";
    else if (budget > 5000 && budget <= 25000) return "€5.000 - €25.000";
    else if (budget > 25000) return "€25.000 +";
    else return "Onbekend";
  }

  // shorten name of file to fit in element and still show filetype
  function truncateFilename(index) {
    let name = data.documenten[index].name;
    let ext = name
      .substring(name.lastIndexOf(".") + 1, name.length)
      .toLowerCase();
    let filename = name.substring(0, name.lastIndexOf("."));

    if (filename.length <= 20) {
      return name;
    }
    filename = filename.substr(0, 20) + (name.length > 20 ? "[...]" : "");
    return filename + "." + ext;
  }

  return (
    <React.Fragment>
      <span className="sub-title">Let op!:</span>{" "}
      <ReactMarkdown className="main-text">{data.let_op}</ReactMarkdown>
      <span className="sub-title">Interessegebieden:</span>
      <span className="main-text">
        {data.interessegebieden.map((item) => item.naam).join(", ")}
      </span>
      <span className="sub-title">Type fonds:</span>
      <span className="main-text">
        {data.type_fonds &&
          data.type_fonds.replace(/([a-z])([A-Z])/g, "$1/$2").replace("_", " ")}
      </span>
      <span className="sub-title">Werkgebied:</span>
      <span className="main-text">
        {data.werkgebieden.map((item) => item.naam).join(", ")}
        {data.regios.length > 0 &&
          ", " +
            data.regios
              .map((item) => item.naam)
              .sort()
              .join(", ")}

        {data.steden.length > 0 &&
          ", " +
            data.steden
              .map((item) => item.naam)
              .sort()
              .join(", ")}
      </span>
      <span className="sub-title">Aanvraagbudget:</span>
      <span className="main-text">{getBudget()}</span>
      <span className="sub-title">Wijze van aanvragen:</span>
      <span className="main-text">
        {data.wijze_van_aanvragen.length > 0 &&
          data.wijze_van_aanvragen
            .map((item) => item.naam)
            .sort()
            .join(", ")}
      </span>
      <span className="sub-title">Bijlagen:</span>
      {data.documenten.map((item, i) => (
        <div key={item.id} className="documents">
          <BsFileEarmarkText className="document-icon" />
          <a
            href={config.API_URL + item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {truncateFilename(i)}
          </a>
        </div>
      ))}
    </React.Fragment>
  );
};

export default DetailsSider;
