import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import rootReducer from './rootReducer';

const middleware = [
	thunk
];

export const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(...middleware),
		// use chrome devtool plugin
		window.devToolsExtension ? window.devToolsExtension() : f => f
	)
);

export const persistor = persistStore(store);;